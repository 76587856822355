import React from "react"

import SEO from "../components/seo"
import Header from "../components/Header"
import Notice from "../components/Notice"

const SecondPage = () => (
  <>
    <SEO title="Page two" />
    <Header />
    <Notice />
  </>
)

export default SecondPage
