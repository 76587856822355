import React from "react"
import { useTranslation } from "react-i18next"

import "../styles/Notice.styl"

export default function Notice() {
  const { t } = useTranslation()

  return (
    <>
      <div className="notice__wrapper">
        <h1 className="notice__title">{t("notice.title")}</h1>
        <p>
          Aviso dirigido a los titulares de datos personales-corporativos que
          obran en posesión de Fark Corp S.A. de C.V. con objeto de cumplir los
          Lineamientos del Aviso de Privacidad establecidos en el Diario Oficial
          de la Federación publicados el día 17 de enero de 2017 y las leyes
          anunciadas en dicha publicación.
        </p>
        <br className="p--space" />
        <p>
          Fark Corp, S.A. de C.V. (en adelante Fark Corp) con domicilio en calle
          Luis Lusati, Manzana 12, Lote 14, Colonia México Nuevo, Atizapán de
          Zaragoza, Estado de México, C.P. 52966, hace de su conocimiento que
          sus datos personales-corporativos (excluyendo sensibles), recabados de
          forma directa, indirecta y/o personal, que actualmente o en el futuro
          formen parte de nuestra base de datos, serán tratados por el personal
          de Fark Corp de acuerdo a la naturaleza de sus trabajos o funciones y
          necesidades de tratar/utilizar con el propósito de:
        </p>
        <br className="p--space" />
        <ol>
          <li>
            Cumplir con aquellas obligaciones legales y jurídicas establecidas
            por nuestras autoridades.
          </li>
          <li> Utilizar y/o tratar sus datos con fines estadísticos.</li>
          <li>
            Utilizar y/o tratar sus datos con fines publicitarios y de fomento
            en mejora de la imagen de Fark Corp.
          </li>
          <li>
            Utilizar sus datos como base de referencia-cartera de clientes.
          </li>
          <li>
            Enviarle/notificarle a su domicilio, correo electrónico, teléfono
            y/o fax invitaciones, ofertas o cualquier iniciativa con fines de
            prospección comercial.
          </li>
          <li>
            Contactarle-notificarle ante algún posible riesgo sanitario de
            acuerdo a reportes de farmacovigilancia y/o tecnovigilancia.
          </li>
        </ol>
        <p>
          Los titulares que deseen ejercer sus derechos de acceso,
          rectificación, cancelación, oposición (ARCO) y la revocación del
          consentimiento, mediante una solicitud al correo electrónico
          contacto@farkcorp.com , las solicitudes deben de incluir:
        </p>
        <br className="p--space" />

        <ul>
          <li>
            El nombre del titular, domicilio y contacto para comunicarle la
            respuesta de la solicitud.
          </li>
          <li>
            Acompañar documentos oficiales que acrediten la identidad del
            titular.
          </li>
          <li>
            Expresar de forma clara y precisa los datos a los que se desee
            aplicar el ejercicio de los derechos ARCO.
          </li>
          <li>
            Anexar cualquier elemento o documento que auxilie la localización de
            los datos.
          </li>
        </ul>
        <br className="p--space" />
        <p>
          Fark Corp dará respuesta en un tiempo no mayor a 30 días hábiles. Fark
          Corp se reserva el derecho de modificar, cambiar, complementar,
          actualizar y/o alterar el presente aviso, en cualquier momento,
          publicando cualquier cambio realizado en el siguiente link:
          <a href="https://farkcorp.com">https://farkcorp.com</a>
        </p>
      </div>
    </>
  )
}
